import React, { MetaHTMLAttributes } from "react";

import { graphql, useStaticQuery } from "gatsby";
import Helmet from "react-helmet";

interface IProps {
  description?: string;
  lang?: string;
  meta?: {
    name?: string | undefined;
    content: string;
    property?: string | undefined;
  }[];
  title: string;
}

interface SiteQuery {
  site: {
    siteMetadata: {
      title: string;
      description: string;
      author: string;
    };
  };
}

const SEO: React.FunctionComponent<IProps> = ({
  description = "Creato - An amazing voting app for creators",
  lang = "en",
  meta = [],
  title = 'Creato',
}) => {
  const { site }: SiteQuery = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );
  const metaDescription = description || site.siteMetadata.description;
  const metaItems: MetaHTMLAttributes<HTMLMetaElement>[] = [
    {
      name: "description",
      content: metaDescription,
    },
    {
      property: "og:title",
      content: title,
    },
    {
      property: "og:description",
      content: metaDescription,
    },
    {
      property: "og:type",
      content: "Creato",
    },
    {
      name: "twitter:card",
      content: "summary",
    },
    {
      name: "twitter:creator",
      content: site.siteMetadata.author,
    },
    {
      name: "twitter:title",
      content: title,
    },
    {
      name: "twitter:description",
      content: metaDescription,
    },
  ];

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={metaItems.concat(meta)}
    />
  );
};

export default SEO;
